import React, { Component } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";


class Sidebars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueId: ''
        }
    }

    render() {
        return (
            <div className="side_bg clearfix">


                <ul>

                    <Sidebar>
                        <Menu>
                            <MenuItem className={document.title === "Spot Dashboard" ? 'active' : ''}>
                                <a href="/dashboard"><i className="fa fa-columns fa-lg"></i><p>Dashboard</p></a>
                            </MenuItem>


                            <SubMenu label={<><i className="fa fa-columns fa-lg"></i><p>Margin </p></>}>
                                <MenuItem className={document.title === "Margin Dashboard" ? 'active' : ''}>
                                    <Link to="/dashboardMargin"><i className="fa fa-columns fa-lg"></i><p>Dashboard </p></Link>
                                </MenuItem>
                                {/* <MenuItem  className={document.title === "Open Order Margin" ? 'active' : ''}>
                                <Link to="/openOrderMargin"><i className="fa fa-columns fa-lg"></i><p>Open Order  </p></Link>
                                </MenuItem> */}
                                <MenuItem className={document.title === "Order History Margin" ? 'active' : ''}>
                                    <Link to="/orderHistoryMargin"><i className="fa fa-columns fa-lg"></i><p>Margin History  </p></Link>
                                </MenuItem>
                                {/* <MenuItem  className={document.title === "Cancel Order Margin" ? 'active' : ''}>
                                    <Link to="/canclOrderMargin"><i className="fa fa-columns fa-lg"></i><p>Cancelled Order  </p></Link>
                                </MenuItem> */}
                            </SubMenu>





                            <MenuItem className={document.title === "Borrow History" ? 'active' : ''}>
                                <a href="/borrowHistory"><i className="fa fa-columns fa-lg"></i><p>Borrowing </p></a>
                            </MenuItem>

                            <MenuItem className={document.title === "Transactions" ? 'active' : ''}>
                                <a href='/transactions'> <i className="fa fa-money fa-lg" ></i><p>Transactions</p></a>
                            </MenuItem>

                            <MenuItem className={document.title === "Referral" ? 'active' : ''}>
                                <a href="/referral"><i className="fa fa-share fa-lg"></i><p>Referral</p></a>
                            </MenuItem>

                            <MenuItem className={document.title === "Deposit" ? 'active' : ''}>
                                <a href="/deposit"><i className="fa fa-university" ></i><p>Deposit</p></a>
                            </MenuItem>
                            <MenuItem className={document.title === "INR Deposit" ? 'active' : ''}>
                                <a href="/inrdeposit/INR"><i className="fa fa-bank" ></i><p>INR Deposit</p></a>
                            </MenuItem>

                            <MenuItem className={document.title === "Withdraw" ? 'active' : ''}>
                                <a href="/withdraw"><i className="fa fa-usd" ></i><p>Withdraw</p></a>
                            </MenuItem>


                            <MenuItem className={document.title === "Verify KYC" ? 'active' : ''}>
                                <a href="/kyc"><i className="fa fa-address-card-o"></i><p>Verify KYC</p></a>
                            </MenuItem>


                            <MenuItem className={document.title === "Activity Log" ? 'active' : ''}>
                                <a href="/activitylog"><i className="fa fa-file-text"></i> <p>Activity Logs</p></a>
                            </MenuItem>

                            <MenuItem className={document.title === "Settings" ? 'active' : ''}>
                                <a href="/setting"><i className="fa fa-cog fa-lg"></i><p>Settings</p></a>
                            </MenuItem>


                            <MenuItem className={document.title === "Personal Details" ? 'active' : ''}>
                                <a href="/personaldetails"><i className="fa fa-user fa-lg"></i> <p>Personal Details</p></a>
                            </MenuItem>




                        </Menu>
                    </Sidebar>



                </ul>




                {/* <ul>
    <li className={document.title === "Spot Dashboard" ? 'active' : ''}><a href="/dashboard"><i className="fa fa-columns fa-lg"></i><p>Dashboard</p></a></li>
    <li className={document.title === "Margin Dashboard" ? 'active' : ''}><a href="/dashboardMargin"><i className="fa fa-columns fa-lg"></i><p>Margin </p></a></li>
    <li className={document.title === "Open Order Margin" ? 'active' : ''}><a href="/openOrderMargin"><i className="fa fa-columns fa-lg"></i><p>Open Order / Margin </p></a></li>
    <li className={document.title === "Order History Margin" ? 'active' : ''}><a href="/orderHistoryMargin"><i className="fa fa-columns fa-lg"></i><p>Order History / Margin </p></a></li>
    <li className={document.title === "Cancel Order Margin" ? 'active' : ''}><a href="/canclOrderMargin"><i className="fa fa-columns fa-lg"></i><p>Cancel Order / Margin </p></a></li>

    <li className={document.title === "Borrow History" ? 'active' : ''}><a href="/borrowHistory"><i className="fa fa-columns fa-lg"></i><p>Borrowing </p></a></li>

    <li><a href="/transactions"><i className="fa fa-money fa-lg" ></i><p>Transactions</p></a></li>
    
    <li className={document.title === "Referral" ? 'active' : ''}><a href="/referral"><i className="fa fa-share fa-lg"></i><p>Referral</p></a></li>
    
    <li className={document.title === "Deposit" ? 'active' : ''}><a href="/deposit"><i className="fa fa-university" ></i><p>Deposit</p></a></li>
    
    <li className={document.title === "Withdraw" ? 'active' : ''}><a href="/withdraw"><i className="fa fa-usd" ></i><p>Withdraw</p></a></li>

        
    <li className={document.title === "Verify KYC" ? 'active' : ''}><a href="/kyc"><i className="fa fa-address-card-o"></i><p>Verify KYC</p></a></li>

    <li className={document.title === "Activity Log" ? 'active' : ''}><a href="/activitylog"><i className="fa fa-file-text"></i> <p>Activity Logs</p></a></li>
    
    <li className={document.title === "Settings" ? 'active' : ''}><a href="/setting"><i className="fa fa-cog fa-lg"></i><p>Settings</p></a></li>
    
    <li><a href="/personaldetails"><i className="fa fa-user fa-lg"></i> <p>Personal Details</p></a></li>

</ul> */}






            </div>



        );
    }
}

export default Sidebars;